@use "common";

$onhover-expand: 0.25rem;
$event-bar-height: 2.25rem;

@mixin single-line {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.event {
    @include common.flex(column, nowrap);

    > .caption {
        display: flex;
        align-items: baseline;
        gap: 0.25rem;

        > .name {
            white-space: normal;
            flex-grow: 1;
        }

        .fi {
            flex-shrink: 0;
            position: relative;
            top: 0.05rem;
        }
    }

    > .image {
        background-image: linear-gradient(#bbb, #ccc);

        > img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    > section.people ul {
        @include common.badge-list-inline;

        > li {
            background-color: var(--bg-color-staff);
        }
    }

    > .link {
        &,
        & li {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow-x: hidden;
        }
    }

    > .link, > .location, > .suggest {
        font-size: 0.8rem;
    }

    > section.tags {
        display: flex;
        justify-content: flex-end;
        padding-right: 0.5rem;
        overflow: hidden;

        ul {
            @include common.badge-list-inline;

            flex-wrap: nowrap;
            align-items: center;

            > li {
				@include common.token-colors;
            }
        }
    }

    > section.suggest {
        display: none;
    }

    &[data-cancelled="true"] {
        > .caption > .name {
            text-decoration: line-through;
            color: var(--text-color-muted);
        }

        > .image {
            filter: grayscale(1) opacity(50%);
        }
    }

    ul.calendar > li > .events & {
        background-color: var(--bg-color-event);
    }

    &.dialog {
        > * {
            padding: 0.5rem 1rem;
            flex-shrink: 0;
        }

        > :not(:last-child) {
            border-bottom: 1px solid var(--border-color-event);
        }

        .image {
            padding: 0;
        }
    }
}

@media (min-width: common.$single-column-max-width) {
    ul.calendar > li > .events > div {
        width: common.$event-width;
        height: common.$event-height;
        margin: (common.$event-spacing / 2);

        &:hover {
            z-index: 1;
        }

        .event {
            min-height: 100%;

            > .caption {
                flex-wrap: nowrap;
                padding: 0.4rem 0.5rem;

                > .name {
                    @include single-line;
                }
            }

            > .image {
                height: 120px;
            }

            > .tags {
                flex-grow: 1;
                padding-bottom: 0.5rem;
                align-items: flex-end;
            }

            > .link,
            > .location,
            > .people {
                display: none;
            }

            &:hover {
                margin-left: -$onhover-expand;
                margin-top: -$onhover-expand;
                width: common.$event-width + 2 * $onhover-expand;
                min-height: common.$event-height + 2 * $onhover-expand;
                padding-top: $onhover-expand;
                padding-bottom: $onhover-expand;

                box-shadow: 0 0 0.5rem var(--shadow-color-event);

                > * {
                    padding: 0.5rem 0.5rem + $onhover-expand;

                    &:not(:first-child) {
                        border-top: 1px solid var(--border-color-event);
                    }

                    &:last-child {
                        flex-grow: 1;
                    }
                }

                > .caption {
                    padding-top: 0.4rem;

                    > .name {
                        white-space: normal;
                    }
                }

                > .image {
                    display: none;
                }

                > .people {
                    display: flex;
                }

                > .link,
                > .location {
                    display: block;
                }

                > .suggest {
                    display: block;
                }
            }
        }
    }
}

@media (max-width: common.$single-column-max-width) {
    ul.calendar > li > .events > div {
        width: 100%;
        height: $event-bar-height;
        margin: 0.25rem 0;
    }

    ul.calendar > li > .events .event {
        position: relative;
        flex-direction: row;
        align-items: center;
        height: 100%;

        padding-left: 0.5rem;

        > * {
            height: 100%;
        }

        > .caption {
            align-items: center;

            flex-grow: 1;
            overflow-x: hidden;
            padding: 0;

            > .name {
                @include single-line;
            }
        }

        > .link,
        > .location,
        > .people {
            display: none;
        }

        > .image {
            position: absolute;
            right: 0;

            $mask-image: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
            mask-image: $mask-image;
            -webkit-mask-image: $mask-image;

            z-index: 2;
        }

        > section.tags {
            flex-grow: 0;
            flex-shrink: 0;
            background-color: var(--bg-color-event);
            min-width: 2.5rem;

            ul {
                > li {
                    z-index: 3;
                }
            }
        }
    }
}
