@use "common";

@import "topbar";
@import "event";

@import "../node_modules/flag-icons/css/flag-icons.min.css";

@import "globals";

html,
body {
	padding: 0;
	display: grid;

	grid-template-columns: 1fr;
	grid-template-rows: auto 1fr;
	grid-template-areas: "topbar" "main";

	height: 100vh;
	width: 100vw;

	overflow: hidden;

	color: rgb(33, 37, 41);
}

ul, ol, dl, li {
	list-style-type: none;
	margin: 0;
	padding: 0;
}

* {
	box-sizing: border-box;
}

main {
	grid-area: main;
	overflow-y: auto;
	scrollbar-width: none; // Doesn't work in Safari

	&:focus { outline: none; }

	.loadmore {
		@include common.flex(row, nowrap);
		justify-content: center;
		padding: 1rem 0;

		&.past {
			border-bottom: 5px dotted #eee;
		}

		&.future {
			border-top: 5px dotted #eee;
		}

		&.past, &.future {
			button {
				@include common.button;
				background-color: var(--bg-color-topbar);
			}
		}
	}
}

.flag-icon {
	flex-shrink: 0;
}

.backdrop {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
}

.dialog {
	position: fixed;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	background-color: white;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
	z-index: 1001;
	overflow-y: auto;
	max-height: 90vh;
	max-width: 90vw;
}