@use "common";

ul.calendar {
    > li {
        &:not(:first-child) {
            border-top: 5px dotted #eee;
        }
    }
    > li.gap {
        section.message {
            margin-block: (common.$event-spacing / 2);
            text-align: center;

            @media (min-width: common.$dates-on-top-max-width) {
                width: common.$row-header-width;
            }
        }
    }
    > li.weekend {
        position: relative;
        @include common.flex(row, nowrap);

        @media (max-width: common.$dates-on-top-max-width) {
            flex-flow: column nowrap;
            align-items: center;
        }

        > .dates {
            margin: (common.$event-spacing / 2);

            @media (min-width: common.$dates-on-top-max-width) {
                flex: 0 0 common.$row-header-width;
                height: common.$event-height;
                display: grid;
                grid-template-columns: 1fr 1fr;
            }

            @media (max-width: common.$dates-on-top-max-width) {
                @include common.flex(row, nowrap);
                justify-content: center;
            }

            > .date {
                @media (max-width: common.$dates-on-top-max-width) {
                    @include common.flex(row, nowrap);
                    flex: 0 0 auto;
                    margin: 0;
                }

                @include common.flex(column, nowrap);
                align-items: center;
                justify-content: center;

                .day {
                    font-size: 5rem;
                    margin-bottom: -1.5rem;
                }

                .month {
                    font-size: 1.5rem;
                    text-transform: lowercase;
                }

                .year {
                    color: #ccc;
                }

                @media (max-width: common.$dates-on-top-max-width) {
                    .day,
                    .month,
                    .year {
                        margin: 0;
                        flex: 0 0 auto;
                    }

                    .day::after,
                    .year::before {
                        content: "\a0";
                    }

                    &:first-child::after {
                        content: "\a0–\a0";
                    }

                    .day,
                    .month,
                    .year,
                    &:first-child::after {
                        font-size: 1.5rem;

                        @media (max-width: 60rem) {
                            font-size: 1rem;
                        }
                    }
                }
            }
        }

        > .events {
            flex: 0 1 auto;
            @include common.flex(row, wrap);
            align-items: center;

            @media (max-width: common.$dates-on-top-max-width) {
                justify-content: center;
                margin-top: 0;
            }

            @media (max-width: common.$single-column-max-width) {
                width: 100%;
            }
        }

        &.current-week::before {
            content: "CURRENT WEEK";
            position: absolute;
            transform: rotate(-90deg);
            transform-origin: center;
            margin-top: (common.$event-height/2 - 0.5rem);
            margin-left: (-(common.$event-height)/2 + 1rem);
            text-transform: uppercase;
            font-weight: bold;
            color: #ddd;
            font-size: 1.5rem;

            @media (max-width: common.$dates-on-top-max-width) {
                transform: none;
                margin: 0;
                margin-top: -0.25rem;
                width: 100%;
                text-align: center;
                font-size: 1rem;
            }

            @media (max-width: 60rem) {
                margin-top: -0.5rem;
            }
        }
    }
}
