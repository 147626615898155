@use "common";

@mixin dropdown {
	position: absolute;
	z-index: 5;
	max-height: 50dvh;
	overflow-y: auto;
	overflow-x: hidden;

	&:not(.show) { display: none; }

	background-color: white;
	box-shadow: black 0 0 32px;
	padding: 0.5rem;
}

$button-font-size: 0.9rem;

@mixin picker-button {
	@include common.button;
	
	align-self: stretch;
	border-radius: 0;
	padding: 0 1rem;
	font-size: $button-font-size;
	font-weight: bold;
	color: var(--text-color-navbar-button);
	height: 100%;
	background: white;
	border: none;
}

@mixin topbar-button {
	@include common.button;

	height: 100%;
	background-color: transparent;
	border-radius: 0;
	padding: 0 1rem;
	font-size: $button-font-size;
	font-weight: bold;
	color: var(--text-color-navbar-button);
}

$filters-under-search-min-width: 57rem;

nav {
	display: grid;

	grid-template-areas: "logo search buttons" " . search .";
	grid-template-columns: auto 1fr auto;
	grid-template-rows: 2.5rem auto;

	> .logo { grid-area: logo; }
	> .search { grid-area: search; }
	> .buttons { grid-area: buttons; }

	@media (max-width: 60rem) {
		grid-template-columns: minmax(0, auto) 1fr auto;
		grid-template-areas: "logo . buttons" "search search search";
	}

	background-color: var(--bg-color-topbar);

	a.logo {
		padding-left: 0.5rem;
		padding-right: 1.5rem;
		color: white;
		font-family: var(--font-pacifico), cursive;
		font-size: 1.625rem;
		text-decoration: none;
		overflow: hidden;
	}

	> .search {
		position: relative;
		flex-grow: 1;
		margin: 0.25rem;
		
		display: grid;
		grid-template-rows: 2rem auto;
		grid-template-columns: auto 1fr auto;
		grid-template-areas: "regions search years" ". filters .";

		@media (max-width: 40rem) {
			grid-template-areas: "regions search years" "filters filters filters";
		}

		> .regions { grid-area: regions; }
		> input[type="search"] { grid-area: search; }
		> .years { grid-area: years; }
		> .filters { grid-area: filters; }
		> .suggestions { grid-area: filters; }	

		> .regions {
			position: relative;

			> button.show-regions {
				@include picker-button;
				padding-right: 0.75rem;
				border-start-start-radius: 10rem;
				border-end-start-radius: 10rem;
			}

			> .regions {
				@include common.flex(column, nowrap);
				@include dropdown();
				padding-right: 1.5rem; // For the scrollbar
			
				ul {
					white-space: nowrap;
				}
			}
		}

		> .years {
			position: relative;

			> button.show-years {
				@include picker-button;
				padding-left: 0.75rem;
				border-start-end-radius: 10rem;
				border-end-end-radius: 10rem;
			}

			> .years {
				@include common.flex(row, wrap);
				@include dropdown();
				right: 0;
				gap: 0.5rem;
				align-items: baseline;

				ul {
					display: grid;
					grid-template-columns: repeat(5, auto);
					gap: 0.25rem;

					li {
						@include common.badge;
						background-color: var(--bg-color-year);
						cursor: pointer;

						&.selected {
							background-color: rgb(13, 110, 253);
						}
					}
				}
			}
		}

		> input[type="search"] {
			min-width: 4rem;
			border-inline: 1px solid var(--bg-color-topbar);
			padding-inline: 0.5rem;
			&:focus { outline: none; }
		}

		> ul.filters {
			&:empty { display: none; }

			color: white;
			padding-top: 0.25rem;

			> li {
				text-indent: -1.5rem;
				padding-inline: 1.5rem;

				button {
					padding: 0;
					color: inherit;
					min-width: 1.5rem;
					font-weight: bold;
					padding-bottom: 0.125rem;

					&:hover {
						&:not(:first-child) { text-decoration: line-through; }
						background-color: #444;
					}
				}
			}
		}

		> ul.suggestions {
			position: absolute;
			top: 100%;
			left: 0;
			right: 0;
			margin-top: 0.25rem;
			z-index: 5;
			background-color: white;
			box-shadow: black 0 16px 32px;

			@include common.flex(column, nowrap);
			display: none;
			&.show { display: flex; }

			> li {
				&:focus { background-color: #ddd; outline: none; }
				&:hover { background-color: #ddd; cursor: pointer; }
				padding: 0.25rem 0.5rem;
				border-inline: 1px solid var(--bg-color-topbar);
				border-bottom: 1px solid var(--bg-color-topbar);
			}
		}
	}

	> .buttons {
		@include common.flex(row, nowrap);

		> .info {
			position: relative;
			> button.show-info { @include topbar-button; }
			> .info-panel {
				@include common.flex(column, nowrap);
				@include dropdown();
				right: 1rem;
				width: clamp(0rem, 90dvw, 22rem);
				gap: 0.5rem;
				> section + section {
					padding-top: 0.5rem;
					border-top: 1px dotted var(--bg-color-topbar)
				}
			}
		}

		> button.goto-today {
			@include topbar-button;
		}
	}
}

.region-tree {
	button { padding: 0; }

	input[type="checkbox"] {
		& + button {
			margin-left: 0.25rem;
			& + button {
				margin-left: 0.25rem;
			}
		}
	}

	ul {
		margin-left: 1.5em;
	}
}
