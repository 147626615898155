:root {
    --text-color-default: #212529;
    --text-color-muted: #6c757d;
    --text-color-navbar-button: hsl(0, 0%, 65%);

    --bg-color-staff: #6c757d;
    --bg-color-region: #fca370;
    --bg-color-year: rgb(33, 37, 41);
    --bg-color-wsdc: #dc3545;
    --bg-color-hotel: #198754;
    --bg-color-cancelled: #6c757d;
    --bg-color-videos: #0dcaf0;
    --bg-color-results: #0d6efd;
    --bg-color-event-token: #635bff;

    --bg-color-event: #dee2e6;
    --bg-color-topbar: #212529;
    --bg-color-input-primary: #0d6efd;

    --border-color-sidebar: rgba(33, 37, 41, 0.25);
    --border-color-event: #ced4da;
    --border-color-topbar: #373b3e;

    --shadow-color-event: #6c757d;

    --checkmark-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
}

$topbar-height: 3.5rem;
$row-header-width: 18rem;

$event-width: 18rem;
$event-height: 12rem;
$event-spacing: 1rem;

$dates-on-top-max-width: ($row-header-width + $event-spacing * 4 + $event-width * 3);
$single-column-max-width: ($event-spacing * 3 + $event-width * 2);

@mixin token-colors {
    &.wsdc {
        background-color: var(--bg-color-wsdc);
    }
    &.hotel {
        background-color: var(--bg-color-hotel);
    }
    &.cancelled {
        background-color: var(--bg-color-cancelled);
    }
    &.videos {
        background-color: var(--bg-color-videos);
    }
    &.results {
        background-color: var(--bg-color-results);
    }
    &.staff {
        background-color: var(--bg-color-staff);
    }
    &.region {
        background-color: var(--bg-color-region);
        color: black;
    }
    &.year {
        background-color: var(--bg-color-year);
    }
    &.event {
        background-color: var(--bg-color-event-token);
    }
}

@mixin badge {
    display: inline-block;
    padding: 0.35em 0.65em;
    font-size: 0.75em;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    border-radius: 0.375rem;
    
    @include token-colors;
}

@mixin badge-list-inline {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 0.25rem;
    align-items: baseline;

    > li {
        @include badge;
    }
}

@mixin button {
    display: inline-block;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    line-height: 1.5;
    color: white;
    border: 1px solid #212529;
    border-radius: 0.375rem;
    transition:
        color 0.15s ease-in-out,
        background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out,
        box-shadow 0.15s ease-in-out;

    &:hover {
        color: white;
        background-color: #424649;
        border-color: #373b3e;
    }

    &:active {
        color: #fff;
        background-color: #4d5154;
        border-color: #373b3e;
    }
}

@mixin button-dark {
    @include button;

    color: white;
    border: 1px solid #212529;
    background-color: black;

    &:hover {
        color: white;
        background-color: #424649;
        border-color: #373b3e;
    }

    &:active {
        color: #fff;
        background-color: #4d5154;
        border-color: #373b3e;
    }
}

@mixin checkbox {
    flex-shrink: 0;
    width: 1.2em;
    height: 1.2em;
    margin-top: 0.25em;
    vertical-align: top;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-color: white;
    border: 1px solid #dee2e6;
    border-radius: 0.25em;

    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    }

    &:checked {
        background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='m6 10 3 3 6-6'/%3e%3c/svg%3e");
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: contain;
        background-color: #0d6efd;
        border-color: #0d6efd;
    }
}

@mixin no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
    &::-webkit-scrollbar { display: none; }
}

@mixin flex($direction, $wrapping) {
    display: flex;
    flex-flow: $direction $wrapping;
}

input[type="checkbox"] {
    width: 1rem;
    height: 1rem;
    cursor: pointer;
    appearance: none;
    background: var(--bg-color-event);
    border-radius: 0.25rem;
    outline: none;
    vertical-align: baseline;
    top: 0.125rem;
    position: relative;    
    display: inline-block;

    &:checked {
        background-color: var(--bg-color-input-primary);
        background-image: var(--checkmark-image);
    }
}